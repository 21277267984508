import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="8" height="8" transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.65674 0)" fill="white"/>
  </Svg>
)

export default Icon
