import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.2469 15.0354V11.5055C27.3156 8.47812 26.1613 5.55144 24.0445 3.38601C21.928 1.22058 19.0279 0 15.9997 0C12.9719 0 10.0715 1.22058 7.95493 3.38601C5.83835 5.55144 4.6838 8.47812 4.75252 11.5055V15.0585C3.35123 15.6152 2.14929 16.5794 1.30224 17.8267C0.454832 19.074 0.001242 20.5464 0 22.0545V32.626C0 34.6245 0.793641 36.5412 2.20663 37.9542C3.61961 39.3671 5.53644 40.1608 7.53451 40.1608H24.4651C26.4636 40.1608 28.3799 39.3671 29.793 37.9542C31.2059 36.5412 32 34.6244 32 32.626V22.0306C31.9984 20.5231 31.5448 19.0502 30.6978 17.8029C29.8504 16.556 28.6484 15.5918 27.2471 15.0351L27.2469 15.0354ZM15.9964 3.71555C18.0603 3.71717 20.039 4.53911 21.4969 6.00022C22.9548 7.46092 23.7718 9.4416 23.769 11.5055V14.5195H8.2233V11.5055C8.22047 9.4416 9.03756 7.46092 10.4954 6.00022C11.9533 4.53911 13.932 3.71711 15.996 3.71555H15.9964ZM28.522 32.6263C28.522 33.7025 28.0946 34.7343 27.3338 35.4952C26.5729 36.2561 25.5408 36.6838 24.4649 36.6838H7.52913C6.45324 36.6838 5.42114 36.2561 4.6602 35.4952C3.89931 34.7343 3.47202 33.7026 3.47202 32.6263V22.031C3.47202 20.9551 3.89936 19.923 4.6602 19.162C5.42109 18.4011 6.45324 17.9739 7.52913 17.9739H24.4649C25.5408 17.9739 26.5729 18.4012 27.3338 19.162C28.0947 19.9229 28.522 20.9551 28.522 22.031V32.6263Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0139 19H15.9843L15.9475 19.0018H15.9178L15.8514 19.0071L15.8146 19.0125C15.7058 19.0268 15.5986 19.0515 15.4954 19.0926L15.4549 19.1086L15.3903 19.1372L15.3717 19.1461L15.3513 19.1551L15.3272 19.1676L15.3124 19.1765L15.2552 19.2086L15.2073 19.2408L15.1999 19.2461L15.1464 19.2833L15.093 19.3259L15.0432 19.3684L14.9953 19.4146L14.953 19.4607L14.9088 19.5122L14.8702 19.5637L14.8315 19.617L14.826 19.6277L14.7981 19.6723L14.7889 19.6866L10.1739 27.6865C9.89696 28.1683 9.95414 28.7639 10.3216 29.1867L14.9366 34.5199C15.1987 34.8238 15.5884 35 16 35C16.4116 35 16.8011 34.8241 17.0634 34.5199L21.6784 29.1867C22.0459 28.7635 22.103 28.168 21.8261 27.6865L17.2111 19.6866L17.2019 19.6723L17.1685 19.6173L17.1351 19.5711L17.1295 19.564L17.0909 19.5125L17.0467 19.461L17.0025 19.4131L16.9546 19.367L16.9067 19.3262L16.8532 19.2836L16.7998 19.2464L16.7445 19.2092L16.7333 19.2039L16.687 19.1771L16.6721 19.1682L16.648 19.1557L16.6276 19.1467L16.6091 19.1378L16.5668 19.1182L16.5445 19.1092L16.504 19.0932C16.4005 19.0524 16.2936 19.0274 16.1848 19.0131L16.148 19.0077L16.0816 19.0024H16.0519L16.0151 19.0006L16.0139 19ZM15.9991 23.0782L13.0548 28.1819L15.9991 31.5828L18.9434 28.1819L15.9991 23.0782Z" fill="white"/>
  </svg>
)

export default Icon
