import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="456" height="369" viewBox="0 0 456 369" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.866025" width="261.713" height="261.713" transform="matrix(0.866025 -0.5 0.866025 0.5 0.116025 237.947)" stroke="url(#paint0_linear_7_4404)"/>
    <rect width="191" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 62.4614 191.745)" fill="url(#paint1_linear_7_4404)"/>
    <rect width="140" height="140" transform="matrix(0.866025 -0.5 0.866025 0.5 106.461 191.245)" fill="#1E2939"/>
    <rect width="191" height="45" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.1052 191.236)" fill="url(#paint2_linear_7_4404)"/>
    <rect width="191" height="45" transform="matrix(0.866025 -0.5 2.20305e-08 1 227.105 286.745)" fill="url(#paint3_linear_7_4404)"/>
    <g filter="url(#filter0_f_7_4404)">
      <rect width="25.468" height="25.468" transform="matrix(0.456362 -0.889794 0.998765 -0.0496757 251.907 67.6244)" fill="url(#paint4_linear_7_4404)"/>
      <rect width="25.468" height="25.468" transform="matrix(0.998765 -0.0496757 0.542403 0.840118 251.907 67.6244)" fill="url(#paint5_linear_7_4404)"/>
      <rect width="25.468" height="25.468" transform="matrix(0.456362 -0.889794 0.542403 0.840118 277.297 66.389)" fill="url(#paint6_linear_7_4404)"/>
    </g>
    <rect width="15.4489" height="15.4489" transform="matrix(0.994859 0.101272 0.409725 0.912209 185.56 6.26404)" fill="url(#paint7_linear_7_4404)"/>
    <rect width="15.4489" height="15.4489" transform="matrix(0.409725 0.912209 -0.585134 0.810937 185.56 6.26404)" fill="url(#paint8_linear_7_4404)"/>
    <rect width="15.4489" height="15.4489" transform="matrix(0.994859 0.101272 -0.585134 0.810937 191.862 20.3372)" fill="url(#paint9_linear_7_4404)"/>
    <rect width="35.6951" height="35.6951" transform="matrix(0.965926 0.258819 0.258819 0.965926 214.86 146.62)" fill="url(#paint10_linear_7_4404)"/>
    <rect width="35.6951" height="35.6951" transform="matrix(0.258819 0.965926 -0.707107 0.707107 214.86 146.62)" fill="url(#paint11_linear_7_4404)"/>
    <rect width="35.6951" height="35.6951" transform="matrix(0.965926 0.258819 -0.707107 0.707107 224.045 181.044)" fill="url(#paint12_linear_7_4404)"/>
    <rect width="141.284" height="157.102" transform="matrix(0.866025 -0.5 2.20305e-08 1 227.105 104.642)" fill="url(#paint13_linear_7_4404)"/>
    <rect width="139.719" height="156.318" transform="matrix(0.866025 0.5 -2.20305e-08 1 106.461 34.9266)" fill="url(#paint14_linear_7_4404)"/>
    <defs>
      <filter id="filter0_f_7_4404" x="245.907" y="37.698" width="62.8271" height="57.3225" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_7_4404"/>
      </filter>
      <linearGradient id="paint0_linear_7_4404" x1="-6.18529e-07" y1="319.634" x2="348.289" y2="244.938" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7_4404" x1="-4.49689e-07" y1="232.383" x2="253.216" y2="178.078" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_7_4404" x1="-4.49689e-07" y1="54.75" x2="144.844" y2="-77.0985" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint3_linear_7_4404" x1="-4.49689e-07" y1="54.75" x2="144.844" y2="-77.0985" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint4_linear_7_4404" x1="-5.99616e-08" y1="30.986" x2="33.7639" y2="23.7449" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint5_linear_7_4404" x1="-5.99616e-08" y1="30.986" x2="33.7639" y2="23.7449" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint6_linear_7_4404" x1="-5.99616e-08" y1="30.986" x2="33.7639" y2="23.7449" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint7_linear_7_4404" x1="-3.63728e-08" y1="18.7961" x2="20.4812" y2="14.4037" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint8_linear_7_4404" x1="-3.63728e-08" y1="18.7961" x2="20.4812" y2="14.4037" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint9_linear_7_4404" x1="-3.63728e-08" y1="18.7961" x2="20.4812" y2="14.4037" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint10_linear_7_4404" x1="-8.40403e-08" y1="43.429" x2="47.3224" y2="33.28" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint11_linear_7_4404" x1="-8.40403e-08" y1="43.429" x2="47.3224" y2="33.28" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint12_linear_7_4404" x1="-8.40403e-08" y1="43.429" x2="47.3224" y2="33.28" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint13_linear_7_4404" x1="77.776" y1="171.49" x2="123.722" y2="-34.715" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1093EA"/>
        <stop offset="0.828125" stopColor="#1093EA" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint14_linear_7_4404" x1="88.912" y1="166.862" x2="74.9811" y2="2.46414" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1093EA"/>
        <stop offset="1" stopColor="#1093EA" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
