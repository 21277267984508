import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="49" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24.2487" height="24.2487" transform="matrix(0.866025 -0.5 0.866025 0.5 0 12.1244)" fill="url(#paint0_linear_7_4272)"/>
    <rect width="24.2487" height="24.2487" transform="matrix(0.866025 0.5 -2.20305e-08 1 0 12.1244)" fill="url(#paint1_linear_7_4272)"/>
    <rect width="24.2487" height="24.2487" transform="matrix(0.866025 -0.5 2.20305e-08 1 20.9478 24.2486)" fill="url(#paint2_linear_7_4272)"/>
    <defs>
      <linearGradient id="paint0_linear_7_4272" x1="-5.7091e-08" y1="29.5026" x2="32.1475" y2="22.6081" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7_4272" x1="-5.7091e-08" y1="29.5026" x2="32.1475" y2="22.6081" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_7_4272" x1="-5.7091e-08" y1="29.5026" x2="32.1475" y2="22.6081" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
