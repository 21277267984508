import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M30.7334 24.9399C35.0333 24.9399 38.5638 21.4094 38.5638 17.1095C38.5638 12.8547 35.1691 9.36933 30.9598 9.27904C29.783 5.65791 26.4787 3.16836 22.5861 3.16836C22.0882 3.16836 21.6354 3.21351 21.1375 3.30413C19.282 1.22218 16.6567 0 13.8505 0C8.50963 0 4.16421 4.34542 4.16421 9.6863V10.1391C1.62955 11.497 0 14.1223 0 17.0643C0 21.3642 3.53052 24.8948 7.83044 24.8948H17.6978V30.4169C15.4346 31.0054 13.6694 32.7706 13.0809 35.0338H5.29594C4.43586 35.0338 3.71158 35.7581 3.71158 36.6181C3.71158 37.4782 4.43586 38.2025 5.29594 38.2025H13.0812C13.8052 40.9632 16.2947 43 19.2822 43C22.2696 43 24.7592 40.9632 25.4831 38.2022H33.2684C34.1285 38.2022 34.8528 37.4779 34.8528 36.6178C34.8528 35.7577 34.1285 35.0334 33.2684 35.0334L25.4831 35.0338C24.8946 32.7706 23.1294 31.0053 20.8662 30.4168L20.8665 24.9399L30.7334 24.9399ZM22.4955 36.6178C22.4955 38.3831 21.0469 39.8316 19.2817 39.8316C17.5164 39.8316 16.0678 38.3831 16.0678 36.6178C16.0678 34.8525 17.5164 33.404 19.2817 33.404C21.0469 33.4043 22.4955 34.8525 22.4955 36.6178ZM3.16817 17.0641C3.16817 15.0274 4.43554 13.2621 6.38202 12.6284C7.15145 12.4021 7.6042 11.6326 7.42299 10.8632C7.33237 10.4559 7.33237 10.0486 7.33237 9.68641C7.33237 6.11046 10.2292 3.16812 13.8503 3.16812C15.9777 3.16812 17.9694 4.20909 19.1912 5.97435C19.5985 6.51741 20.2776 6.78892 20.9565 6.60802C21.4995 6.47225 22.0429 6.38164 22.586 6.38164C25.347 6.38164 27.746 8.4639 28.1987 11.1794C28.2438 11.5867 28.4702 11.9489 28.8323 12.2204C29.1945 12.4468 29.6018 12.5826 30.0091 12.4919C30.2806 12.4468 30.5522 12.4013 30.7785 12.4013C33.3587 12.4013 35.4406 14.4836 35.4406 17.0634C35.4406 19.6436 33.3584 21.7255 30.7785 21.7255L7.83013 21.7262C5.24997 21.7713 3.16803 19.6439 3.16803 17.0641L3.16817 17.0641Z" fill="white"/>
  </Svg>
)

export default Icon
