import MainGradient1 from './MainGradient1.png'
import {Image} from "../../components/Image";
import MainGradient2 from './MainGradient2.png'
import MainGradient3 from './MainGradient3.png'

export const MainLayout = () => {

  return (
    <>
      <Image
        src={MainGradient1}
        width='1500px'
        height='auto'
        style={{position: 'absolute', top: 0, right: 0}}
      />
      <Image
        src={MainGradient2}
        width='1500px'
        height='auto'
        style={{position: 'absolute', top: '1750px', left: 0}}
      />
      <Image
        src={MainGradient3}
        width='1500px'
        height='auto'
        style={{position: 'absolute', top: '3500px', right: 0}}
      />
    </>
    )
}