import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 9.75H28M28 9.75L19.3846 18.5M28 9.75L19.3846 1" stroke="#29CCFF" strokeWidth="1.5"/>
  </Svg>
)

export default Icon
