import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="56" height="33" viewBox="0 0 56 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.6888 8.64871C27.6888 7.83969 27.0043 7.15527 26.1954 7.15527H7.52883C6.71982 7.15527 6.0354 7.83974 6.0354 8.64871C6.0354 9.45768 6.71986 10.1421 7.52883 10.1421H26.1954C27.0669 10.1417 27.6888 9.45725 27.6888 8.64871Z" fill="white"/>
    <path d="M25.2621 15.8045C25.2621 14.9955 24.5777 14.311 23.7687 14.311L13.938 14.3115C13.129 14.3115 12.4446 14.9959 12.4446 15.8049C12.4446 16.6139 13.129 17.2983 13.938 17.2983H23.769C24.6401 17.2979 25.2621 16.6134 25.2621 15.8045V15.8045Z" fill="white"/>
    <path d="M39.8842 0.000123765H1.49343C0.684417 0.000123765 0 0.684584 0 1.49356C0 2.30253 0.684461 2.98699 1.49343 2.98699H39.9465C47.2264 2.98699 53.0752 8.89794 53.0752 16.1158C53.0752 23.3336 47.1643 29.2446 39.9465 29.2446C35.0312 29.2446 30.5511 26.5067 28.2488 22.1513C28.0001 21.6535 27.5022 21.3423 26.9419 21.3423L19.2892 21.3418C18.4802 21.3418 17.7957 22.0263 17.7957 22.8353C17.7957 23.6442 18.4802 24.3287 19.2892 24.3287H26.0091C28.8716 29.244 34.1601 32.2931 39.8846 32.2931C48.7821 32.2306 56 25.0128 56 16.1154C56 7.21805 48.7822 0 39.8846 0L39.8842 0.000123765Z" fill="white"/>
    <path d="M39.8843 5.59998C39.0132 5.59998 38.2666 6.3465 38.2666 7.21763V16.4263V16.7375C38.2666 16.8621 38.3287 16.9242 38.3287 17.0487C38.3907 17.1108 38.3907 17.2354 38.4532 17.2974C38.5153 17.3595 38.5778 17.4841 38.6399 17.5461L42.7466 21.6529C43.0578 21.9641 43.4931 22.1507 43.8664 22.1507C44.3021 22.1507 44.6754 21.9641 44.9861 21.6529C45.6085 21.0305 45.6085 19.9728 44.9861 19.3508L41.3152 15.6798V7.2181C41.5018 6.34657 40.8173 5.60011 39.8842 5.60011L39.8843 5.59998Z" fill="white"/>
  </Svg>
)

export default Icon
