import styled from "styled-components";
import {Flex} from "../../shared/uikit";
import {Link} from "react-router-dom";

export const MenuContainer = styled(Flex)`
  width: 100%;
  max-width: 1500px;
  position: fixed;
  top:0;
  left: 50%;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  padding: 50px 50px 0 50px;
  margin: 0 auto;
  transform: translateX(-50%);
`
export const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 17px;
  color: #FFFFFF;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 30px;
`
export const StyledMobileLink = styled(Link)`
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

export const Card = styled(Flex)`
  width:124px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  font-weight: 700;
  font-size: 14px;
`
export const MobileMenuHeader = styled(Flex)`
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`
export const MobileContainer = styled.div<{ isOpen?: boolean }>`
    overflow: hidden;
    position: fixed;
    top: 40px;
    right:0;
    z-index:11;
    height: 100vh;
    width: ${({ isOpen }) => isOpen ? '100vw' : '0px'};
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    transition: 300ms;
    backdrop-filter: blur(5px);
`
export const MobileBackground = styled.div<{ isOpen?: boolean }>`
    position: fixed;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    filter: blur(18px);
    top: 0;
    right:0;
    height: 100vh;
    width:${({ isOpen }) => isOpen ? '100vw' : '0'};
    transition: 300ms ;
`
export const MobileWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px;
    left: 26px;
    z-index:10;
    padding: 5px 10px;
`