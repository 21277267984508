import React, { ReactNode} from "react"
import {Flex} from "../../components/Box";
import styled from "styled-components";
import {MainLayout} from "../MainLayout";
import {Image} from "../../../../shared/uikit";
import MainGradient1 from "..//MainLayout/MainGradient1.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #101428;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  padding-bottom: 0;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  color: #FFF;
`

interface Pageprops {
  children: ReactNode
}

const Page: React.FC<
  React.HTMLAttributes<HTMLDivElement>
  > = ({ children }) => {
  return (
    <>
      <Container>
         <MainLayout/>
        {children}
      </Container>
    </>
  )
}

export default Page