import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="456" height="415" viewBox="0 0 456 415" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.866025" width="261.713" height="261.713" transform="matrix(0.866025 -0.5 0.866025 0.5 0.116025 283.954)" stroke="url(#paint0_linear_7_4539)"/>
    <rect width="191.812" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 61.7581 256.914)" fill="url(#paint1_linear_7_4539)"/>
    <rect width="191" height="25.4431" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.1052 256.801)" fill="url(#paint2_linear_7_4539)"/>
    <rect width="191.122" height="25.2928" transform="matrix(0.866025 -0.5 2.20305e-08 1 227 352.521)" fill="url(#paint3_linear_7_4539)"/>
    <rect width="191.812" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 61.7578 216.906)" fill="url(#paint4_linear_7_4539)"/>
    <rect width="191" height="25.4431" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.105 216.793)" fill="url(#paint5_linear_7_4539)"/>
    <rect width="191.122" height="25.2928" transform="matrix(0.866025 -0.5 2.20305e-08 1 227 312.513)" fill="url(#paint6_linear_7_4539)"/>
    <rect opacity="0.7" width="191.812" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 61.7578 175.906)" fill="url(#paint7_linear_7_4539)"/>
    <rect width="191" height="25.4431" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.105 175.793)" fill="url(#paint8_linear_7_4539)"/>
    <rect opacity="0.7" width="191.122" height="25.2928" transform="matrix(0.866025 -0.5 2.20305e-08 1 227 271.513)" fill="url(#paint9_linear_7_4539)"/>
    <rect opacity="0.4" width="191.812" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 61.7578 135.906)" fill="url(#paint10_linear_7_4539)"/>
    <rect opacity="0.4" width="191" height="25.4431" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.105 135.793)" fill="url(#paint11_linear_7_4539)"/>
    <rect opacity="0.4" width="191.122" height="25.2928" transform="matrix(0.866025 -0.5 2.20305e-08 1 227 231.513)" fill="url(#paint12_linear_7_4539)"/>
    <rect opacity="0.35" width="191.812" height="191" transform="matrix(0.866025 -0.5 0.866025 0.5 61.7578 95.9061)" fill="url(#paint13_linear_7_4539)"/>
    <rect opacity="0.35" width="191" height="25.4431" transform="matrix(0.866025 0.5 -2.20305e-08 1 62.105 95.7931)" fill="url(#paint14_linear_7_4539)"/>
    <rect opacity="0.35" width="191.122" height="25.2928" transform="matrix(0.866025 -0.5 2.20305e-08 1 227 191.513)" fill="url(#paint15_linear_7_4539)"/>
    <defs>
      <linearGradient id="paint0_linear_7_4539" x1="-6.18529e-07" y1="319.634" x2="348.289" y2="244.938" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7_4539" x1="-4.51602e-07" y1="232.383" x2="254.198" y2="177.635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B1C22"/>
        <stop offset="1" stopColor="#53545F"/>
      </linearGradient>
      <linearGradient id="paint2_linear_7_4539" x1="-4.49689e-07" y1="30.9557" x2="73.7366" y2="-87.7583" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B1C22"/>
        <stop offset="1" stopColor="#53545F"/>
      </linearGradient>
      <linearGradient id="paint3_linear_7_4539" x1="-4.49976e-07" y1="30.773" x2="73.0872" y2="-87.6699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B1C22"/>
        <stop offset="1" stopColor="#53545F"/>
      </linearGradient>
      <linearGradient id="paint4_linear_7_4539" x1="-4.51602e-07" y1="232.383" x2="254.198" y2="177.635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint5_linear_7_4539" x1="-4.49689e-07" y1="30.9557" x2="73.7366" y2="-87.7583" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint6_linear_7_4539" x1="-4.49976e-07" y1="30.773" x2="73.0872" y2="-87.6699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint7_linear_7_4539" x1="-4.51602e-07" y1="232.383" x2="254.198" y2="177.635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint8_linear_7_4539" x1="-4.49689e-07" y1="30.9557" x2="73.7366" y2="-87.7583" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint9_linear_7_4539" x1="-4.49976e-07" y1="30.773" x2="73.0872" y2="-87.6699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint10_linear_7_4539" x1="-4.51602e-07" y1="232.383" x2="254.198" y2="177.635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint11_linear_7_4539" x1="-4.49689e-07" y1="30.9557" x2="73.7366" y2="-87.7583" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint12_linear_7_4539" x1="-4.49976e-07" y1="30.773" x2="73.0872" y2="-87.6699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint13_linear_7_4539" x1="-4.51602e-07" y1="232.383" x2="254.198" y2="177.635" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint14_linear_7_4539" x1="-4.49689e-07" y1="30.9557" x2="73.7366" y2="-87.7583" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint15_linear_7_4539" x1="-4.49976e-07" y1="30.773" x2="73.0872" y2="-87.6699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
