import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="196" height="37" viewBox="0 0 196 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.44123 31.066V12.2723L8.66278 13.4072V31.079L6.44123 31.066ZM14.0251 35.1516V16.5071L16.2467 16.546V35.1516H14.0251ZM0 27.6808V10.5927L2.23432 9.5097V27.1815L0 27.6808ZM14.713 18.5628L0.274502 11.533L2.8727 10.3268L15.6769 16.5265L14.7194 18.5628H14.713ZM29.1116 12.5253L14.2486 4.06225L15.1359 2L30.1984 10.5538L29.118 12.5253H29.1116ZM15.2125 36.1633L0 27.7197L1.07886 25.7467L16.1797 34.1335L15.2125 36.1633ZM1.73 12.1945L0 10.5927L15.1359 2.03891L16.1924 4.06063L1.73 12.1945ZM28.0056 27.5965V11.3385L30.2272 10.5603V27.603L28.0056 27.5965ZM15.2796 27.7279L14.2582 25.724L28.5929 18.1883L29.6143 20.1938L15.2796 27.7343V27.7279ZM14.8008 18.8887L13.8432 16.8524L28.5259 9.6848L29.4835 11.7211L14.8008 18.8887ZM15.2141 36.1568L14.2422 34.1254L28.7764 25.8131L30.2288 27.5576L15.2141 36.1503V36.1568Z" fill="url(#paint0_linear_7_4030)"/>
    <path d="M45 12H55.3V14.64H51.52V26H48.76V14.64H45V12Z" fill="white"/>
    <path d="M65.3008 18.74C66.5274 19.4466 67.1408 20.5133 67.1408 21.94C67.1408 23.1266 66.7208 24.1 65.8808 24.86C65.0408 25.62 64.0074 26 62.7808 26H56.8408V12H62.3608C63.5474 12 64.5541 12.3733 65.3808 13.12C66.2074 13.8666 66.6208 14.8066 66.6208 15.94C66.6208 17.1 66.1808 18.0333 65.3008 18.74ZM62.3608 14.58H59.6008V17.66H62.3608C62.7874 17.66 63.1408 17.5133 63.4208 17.22C63.7141 16.9266 63.8608 16.56 63.8608 16.12C63.8608 15.68 63.7208 15.3133 63.4408 15.02C63.1608 14.7266 62.8008 14.58 62.3608 14.58ZM59.6008 23.42H62.7808C63.2474 23.42 63.6341 23.2666 63.9408 22.96C64.2474 22.64 64.4008 22.24 64.4008 21.76C64.4008 21.2933 64.2474 20.9066 63.9408 20.6C63.6341 20.28 63.2474 20.12 62.7808 20.12H59.6008V23.42Z" fill="white"/>
    <path d="M75.5902 26.28C73.4968 26.28 71.7568 25.58 70.3702 24.18C68.9968 22.7933 68.3102 21.0666 68.3102 19C68.3102 16.92 68.9968 15.1933 70.3702 13.82C71.7568 12.42 73.4968 11.72 75.5902 11.72C76.8568 11.72 78.0235 12.02 79.0902 12.62C80.1702 13.2066 81.0102 14.0066 81.6102 15.02L79.2302 16.4C78.8835 15.7733 78.3902 15.2866 77.7502 14.94C77.1102 14.58 76.3902 14.4 75.5902 14.4C74.2302 14.4 73.1302 14.8266 72.2902 15.68C71.4635 16.5466 71.0502 17.6533 71.0502 19C71.0502 20.3333 71.4635 21.4333 72.2902 22.3C73.1302 23.1533 74.2302 23.58 75.5902 23.58C76.3902 23.58 77.1102 23.4066 77.7502 23.06C78.4035 22.7 78.8968 22.2133 79.2302 21.6L81.6102 22.98C81.0102 23.9933 80.1702 24.8 79.0902 25.4C78.0235 25.9866 76.8568 26.28 75.5902 26.28Z" fill="white"/>
    <path d="M89.8089 26.28C87.7156 26.28 85.9756 25.58 84.5889 24.18C83.2156 22.7933 82.5289 21.0666 82.5289 19C82.5289 16.92 83.2156 15.1933 84.5889 13.82C85.9756 12.42 87.7156 11.72 89.8089 11.72C91.0756 11.72 92.2422 12.02 93.3089 12.62C94.3889 13.2066 95.2289 14.0066 95.8289 15.02L93.4489 16.4C93.1022 15.7733 92.6089 15.2866 91.9689 14.94C91.3289 14.58 90.6089 14.4 89.8089 14.4C88.4489 14.4 87.3489 14.8266 86.5089 15.68C85.6822 16.5466 85.2689 17.6533 85.2689 19C85.2689 20.3333 85.6822 21.4333 86.5089 22.3C87.3489 23.1533 88.4489 23.58 89.8089 23.58C90.6089 23.58 91.3289 23.4066 91.9689 23.06C92.6222 22.7 93.1156 22.2133 93.4489 21.6L95.8289 22.98C95.2289 23.9933 94.3889 24.8 93.3089 25.4C92.2422 25.9866 91.0756 26.28 89.8089 26.28Z" fill="white"/>
    <path opacity="0.6" d="M111.6 23.4V12H113V26H111.8L104 14.6V26H102.6V12H103.8L111.6 23.4ZM117.594 19.6V24.68H124.394V26H116.194V12H124.294V13.32H117.594V18.28H123.794V19.6H117.594ZM125.599 12H135.599V13.32H131.299V26H129.899V13.32H125.599V12ZM142.34 26H140.66L136.6 12H138.08L141.54 24.1L145.18 12H146.82L150.46 24.1L153.92 12H155.4L151.34 26H149.66L146 13.84L142.34 26ZM168.575 24.14C167.175 25.54 165.461 26.24 163.435 26.24C161.408 26.24 159.695 25.54 158.295 24.14C156.895 22.7133 156.195 21 156.195 19C156.195 16.9867 156.895 15.28 158.295 13.88C159.695 12.4667 161.408 11.76 163.435 11.76C165.461 11.76 167.175 12.4667 168.575 13.88C169.975 15.28 170.675 16.9867 170.675 19C170.675 21 169.975 22.7133 168.575 24.14ZM157.595 19C157.595 20.6533 158.155 22.0467 159.275 23.18C160.395 24.3133 161.781 24.88 163.435 24.88C165.088 24.88 166.475 24.3133 167.595 23.18C168.715 22.0467 169.275 20.6533 169.275 19C169.275 17.36 168.715 15.9733 167.595 14.84C166.475 13.6933 165.088 13.12 163.435 13.12C161.781 13.12 160.395 13.6933 159.275 14.84C158.155 15.9733 157.595 17.36 157.595 19ZM183.269 26H181.669L178.389 20.4H174.469V26H173.069V12H178.669C179.815 12 180.802 12.4133 181.629 13.24C182.455 14.0667 182.869 15.0533 182.869 16.2C182.869 17.16 182.582 18.0067 182.009 18.74C181.462 19.46 180.742 19.9533 179.849 20.22L183.269 26ZM178.669 13.32H174.469V19.08H178.669C179.442 19.08 180.102 18.8 180.649 18.24C181.195 17.6533 181.469 16.9733 181.469 16.2C181.469 15.4133 181.195 14.7333 180.649 14.16C180.102 13.6 179.442 13.32 178.669 13.32ZM188.156 18.8L195.076 26H193.236L186.676 19.22V26H185.276V12H186.676V18.4L193.036 12H194.876L188.156 18.8Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_7_4030" x1="-7.11704e-08" y1="43.5653" x2="40.4617" y2="35.8871" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
