import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity="0.6" d="M-0.000101118 0.663507C-0.000101127 0.848341 0.0662498 0.99526 0.189472 1.12322L3.73924 4.75829C3.90037 4.91943 4.06625 4.99526 4.27004 5C4.47383 5 4.64445 4.92417 4.80085 4.75829L8.35535 1.12322C8.47857 0.995261 8.54492 0.843602 8.54492 0.663507C8.54492 0.293839 8.25108 -1.28441e-08 7.88615 -2.87957e-08C7.70606 -3.66678e-08 7.53544 0.0758293 7.40274 0.208531L4.27004 3.43128L1.14208 0.20853C1.00938 0.075829 0.838762 -3.36847e-07 0.658667 -3.44719e-07C0.293738 -3.60671e-07 -0.000101102 0.293838 -0.000101118 0.663507Z" fill="white"/>
  </Svg>
)

export default Icon
