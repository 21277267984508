import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="36" height="51" viewBox="0 0 36 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.4939 0.000773182C18.0696 0.0180435 17.6732 0.217478 17.4063 0.548083C17.4063 0.548083 13.0994 5.88802 8.78505 12.4065C6.62747 15.6658 4.46368 19.2227 2.81761 22.5999C1.17195 25.9771 0 29.1339 0 31.8984C0 41.9375 8.16976 50.1077 18.2093 50.1077C23.2803 50.1077 27.4445 47.7482 30.3983 44.4561C33.3516 41.1636 35.1757 36.9544 35.8306 32.9492C36.3537 29.7493 35.6201 27.0896 34.2499 24.8958C32.8802 22.7024 30.9648 20.9137 29.0232 18.9514C25.1401 15.0269 21.0478 10.5418 20.0191 1.31054C19.9331 0.541588 19.2678 -0.0299441 18.4948 0.00121531L18.4939 0.000773182ZM17.6415 5.04743C19.3368 12.9158 23.4407 17.4996 26.9273 21.024C28.9085 23.0262 30.6643 24.7194 31.7495 26.4568C32.8343 28.1941 33.3396 29.9138 32.9211 32.4731C32.3557 35.9321 30.7178 39.6843 28.2034 42.4871C25.6893 45.2899 22.3808 47.1596 18.2091 47.1596C9.76302 47.1596 2.94733 40.3444 2.94733 31.8979C2.94733 30.0413 3.91036 27.0843 5.46724 23.8905C7.02363 20.6967 9.12983 17.2245 11.2426 14.0327C13.6275 10.4294 16.0142 7.18817 17.6413 5.04712L17.6415 5.04743Z" fill="white"/>
    <path d="M18.2055 24.4344C17.7811 24.4517 17.3847 24.6511 17.1179 24.9817C17.1179 24.9817 15.0232 27.578 12.9207 30.7542C11.8697 32.3423 10.8117 34.0783 9.99542 35.7532C9.17918 37.4284 8.5603 39.0012 8.5603 40.5688C8.5603 45.8194 12.8483 50.107 18.099 50.107C20.7755 50.107 23.0043 48.8429 24.5409 47.1295C26.0776 45.416 27.0052 43.2695 27.344 41.1974C27.6232 39.4892 27.2104 37.9641 26.4764 36.7889C25.7428 35.6141 24.7785 34.7275 23.8533 33.792C22.0025 31.9218 20.2035 29.9905 19.7302 25.7435C19.6442 24.9746 18.9785 24.403 18.2055 24.4342L18.2055 24.4344ZM17.504 29.3376C18.5192 32.4035 20.2932 34.3851 21.7579 35.8655C22.723 36.8408 23.5276 37.632 23.9767 38.3507C24.4257 39.0695 24.61 39.6551 24.4352 40.7225C24.1856 42.2477 23.445 43.9377 22.3471 45.1619C21.2492 46.3861 19.8766 47.1599 18.099 47.1599C14.4414 47.1599 11.5083 44.2264 11.5083 40.5692C11.5083 39.9096 11.9187 38.5365 12.6453 37.0448C13.3723 35.5533 14.3723 33.902 15.3786 32.3813C16.12 31.2611 16.8618 30.2147 17.5037 29.338L17.504 29.3376Z" fill="white"/>
  </Svg>
)

export default Icon
