import {Card, MenuContainer, StyledLink, MobileMenuHeader, MobileContainer, MobileBackground, MobileWrapper, StyledMobileLink} from "./style"
import {BurgerMenuIcon, CloseMenuIcon, MainLogoWithTextIcon} from "../../shared/assets/Svg";
import { Flex, Box } from "../../shared/uikit";
import {useTranslation} from 'react-i18next';
import {PATHS} from "../../config/paths";
import {LangSelector} from "../../widgets/LangSelector";
import {useMatchBreakpoints} from "../../shared/lib/hooks";
import {useState} from "react";

const links = [
  {
    title: 'Token',
    path: PATHS.HOME
  },
  {
    title: 'Features',
    path: PATHS.HOME
  },
  {
    title: 'Developers',
    path: PATHS.HOME
  },
  {
    title: 'Services',
    path: PATHS.HOME
  },
  {
    title: 'Resources',
    path: PATHS.HOME
  }
]

const Menu = () => {

  const {t} = useTranslation()
  const {isMobile, isTablet} = useMatchBreakpoints()
  const isSmall = isMobile || isTablet

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <>
      {
        !isSmall
          ? <MenuContainer>
            <MainLogoWithTextIcon/>
            <Flex>
              {
                links.map((link) => {
                  return (
                    <StyledLink to={link.path} key={link.path}>
                      {t(link.title)}
                    </StyledLink>
                  )
                })
              }
            </Flex>
            <Flex>
              <LangSelector/>
              <Card ml='10px'>
                {t('Bridge to L2')}
              </Card>
            </Flex>
          </MenuContainer>
          :<>
            <MobileMenuHeader>
              <MainLogoWithTextIcon width='150px'/>
              <Flex alignItems='center'>
                <LangSelector/>
                <Box onClick={() => setMenuIsOpen(!menuIsOpen)} ml='20px' pr='8px'>
                  {!menuIsOpen ? <BurgerMenuIcon /> : <CloseMenuIcon />}
                </Box>
              </Flex>
            </MobileMenuHeader>
            <MobileContainer isOpen={menuIsOpen}>
              <MobileBackground isOpen={menuIsOpen} />
              <MobileWrapper>
                {
                  links.map((link) => {
                    return (
                      <StyledMobileLink to={link.path} key={link.path}>
                        {t(link.title)}
                      </StyledMobileLink>
                    )
                  })
                }
              </MobileWrapper>
            </MobileContainer>
          </>
      }
    </>
  )
}

export default Menu