import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './widgets/Menu';
import {PATHS} from './config/paths';
import "./style/Global.css"

const MainPage = lazy(() => import('./views/MainPage'))

const App = () => (
  <Router>
    <Menu/>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={PATHS.HOME} element={<MainPage />} />
      </Routes>
    </Suspense>
  </Router>
);

export default App;
