import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M36 11.0135C36 10.4888 35.7204 10.004 35.2658 9.74147L18.7334 0.196905C18.2801 -0.0656352 17.7182 -0.0656352 17.265 0.196905L0.734197 9.74147C0.279582 10.004 0 10.4884 0 11.0135V30.1027C0.0239273 30.6186 0.283845 31.1115 0.734197 31.3747L17.265 40.9193C17.4914 41.0504 17.7451 41.1156 17.9992 41.1156C18.2528 41.1156 18.5069 41.0504 18.7334 40.9193L35.2658 31.3747C35.7129 31.1072 35.9761 30.6238 36 30.1027V11.0135ZM17.9992 3.16449L33.0632 11.8607V25.9934L23.2317 14.0029C22.6535 13.3277 21.5375 13.3271 20.9603 14.0036L14.521 21.8602L12.011 19.2124C11.4364 18.6251 10.4229 18.6251 9.84571 19.2082L2.9367 26.4403V11.8612L17.9992 3.16449ZM3.82034 29.7659L10.9218 22.3322L18.3303 30.1468C18.8894 30.7368 19.8177 30.7591 20.4067 30.2019C20.9947 29.644 21.0189 28.7147 20.4611 28.1261L16.5571 24.0081L22.0957 17.2505L32.2998 29.6958L17.9993 37.9516L3.82034 29.7659Z" fill="white"/>
    <path d="M12.7279 17.8536C15.157 17.8536 17.1331 15.8775 17.1331 13.4484C17.1331 11.0193 15.157 9.04321 12.7279 9.04321C10.2989 9.04321 8.32275 11.0193 8.32275 13.4484C8.32275 15.8775 10.2989 17.8536 12.7279 17.8536ZM12.7279 11.98C13.5382 11.98 14.1963 12.6388 14.1963 13.4484C14.1963 14.258 13.5382 14.9168 12.7279 14.9168C11.9177 14.9168 11.2595 14.258 11.2595 13.4484C11.2595 12.6388 11.9177 11.98 12.7279 11.98Z" fill="white"/>
  </Svg>
)

export default Icon
