import { ChevronDownIcon } from "../../shared/assets/Svg";
import styled from "styled-components";
import { Box, Flex } from "../../shared/uikit";
import {useTranslation} from "react-i18next";

const Container = styled(Box)`
  position: relative;
  height: 40px;
  width: 40px;
`
const LangBtn = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
`

const LangList = [
  {
    title: "EN",
    key: 'en'
  },
  {
    title: "RU",
    key: 'ru'
  }
]

export const LangSelector = () => {

  const { i18n } = useTranslation()

  return (
    <Container>
      <LangBtn onClick={() => i18n.changeLanguage(LangList[0].key)}>
        {LangList[0].title}
        <ChevronDownIcon ml='5px'/>
      </LangBtn>
    </Container>
  )
}