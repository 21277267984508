import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="527" height="857" viewBox="0 0 527 857" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect opacity="0.3" x="0.866025" width="664.317" height="664.317" transform="matrix(0.866025 -0.5 0.866025 0.5 165.522 428.59)" stroke="url(#paint0_linear_7_4558)"/>
    <rect opacity="0.12" x="0.866025" width="855.313" height="855.313" transform="matrix(0.866025 -0.5 0.866025 0.5 0.116025 428.59)" stroke="url(#paint1_linear_7_4558)"/>
    <rect x="0.866025" width="498.481" height="498.481" transform="matrix(0.866025 -0.5 0.866025 0.5 309.141 428.59)" stroke="url(#paint2_linear_7_4558)"/>
    <defs>
      <linearGradient id="paint0_linear_7_4558" x1="-1.56642e-06" y1="809.469" x2="882.038" y2="620.304" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7_4558" x1="-2.0161e-06" y1="1041.85" x2="1135.25" y2="798.378" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_7_4558" x1="-1.17598e-06" y1="607.702" x2="662.182" y2="465.688" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066FF"/>
        <stop offset="1" stopColor="#2CE0C5"/>
      </linearGradient>
    </defs>
  </Svg>
)

export default Icon
